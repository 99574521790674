import { useEffect, useState } from "react";
import { API_KEY, API_URL } from "./Config";
import axios from "axios";
import { Link, useParams, useLocation } from "react-router-dom";
import KsiazkaCard from "./KsiazkaCard";
import Loader from "./Loader";
import { Helmet } from "react-helmet";

const Autor = () => {

    const [ksiazki, setKsiazki] = useState();
    const [autorId, setAutorId] = useState();
    const [autorName, setAutorName] = useState();
    const [autorSurname, setAutorSurname] = useState();
    const [autorRating, setAutorRating] = useState();
    const [autorBookCount, setAutorBookCount] = useState(0);
    const [autorTags, setAutorTags] = useState();
    const [autorSeries, setAuthorSeries] = useState();
    const [pagecount, setPagecount] = useState();
    var { strona, autor } = useParams();
    const location = useLocation();

    var prev = strona - 1;
    var next = strona;
    next++;

    function properPageChecker() {
        if (strona > pagecount) {
            window.location.replace('/autor/'+autor+'/'+pagecount);
        };
    };

    function setAuthorData(autor) {
        let tempaut = autor.split('_');
        axios.get(API_URL, {
            params: {
                type: 'author',
                authorname: tempaut[0],
                authorsurname: tempaut.pop()
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                console.log(response);
                setAutorId(response.data.data.autor_id);
                setAutorName(response.data.data.autor_name);
                setAutorSurname(response.data.data.autor_surname);
                setAuthorSeries(response.data.data.series);
                setAutorTags(response.data.data.tags);
                setAutorRating(response.data.data.rating);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
        
    };

    function loadKsiazki(page) {
        axios.get(API_URL, {
            params: {
                type: 'books',
                limit: 8,
                authorid: autorId,
                curentpage: page,
                sortby: 'data_wydania',
                sorttype: 'DESC'
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                setKsiazki(response.data.data.items);
                setPagecount(response.data.data.total_pages);
                setAutorBookCount(response.data.data.total_items);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    useEffect(() => {
        setAuthorData(autor);
    }, []);

    useEffect(() => {
        properPageChecker();
    }, [pagecount]);

    useEffect(() => {
       autorId && loadKsiazki(strona);
    }, [autorId, strona]);

    useEffect(() => {
        window.scroll(0,0);
      }, [location]);

    return(
        <>
            <Helmet>
                <title>{autorName + ' ' + autorSurname}</title>
                <meta property="og:title" content={"Autor: "+ autorName + ' ' + autorSurname} />
                <meta property="og:description" content={"Liczba książek: "+autorBookCount+" | Średnia ocena: "+autorRating+"/10 | Kategoria: "+autorTags} />
                <meta property="og:image" content="https://ksiazki.jakubratajczak.pl/writ.jpg" />
            </Helmet>
            <div className="row aut-info-row">
                <div className="col-lg-6 aut-name-surname">
                    <p className="aut-name">{autorName}</p>
                    <p className="aut-surname">{autorSurname}</p>
                    <p className="aut-tagi">Średnia ocena autora: <span className="grratingvalue grubasek autratadd">{autorRating}</span>/10</p>
                    <p className="aut-tagi"><span className="aut-book-counter">{autorBookCount} <i className="fas fa-book" aria-hidden="true"></i></span><span className="langdiv">|</span>{
                        autorTags && autorTags.map((tag, ndx) =>{
                            const isLast = autorTags.length - 1 === ndx;
                            return <>{tag}{!isLast && ','} </>
                        })
                    }</p>
                </div>
                <div className="col-lg-6 aut-series">
                    {(autorSeries && autorSeries != '') &&  <>
                        <p className="aut-serie-title">Serie:&nbsp;
                            {autorSeries && autorSeries.map((seria, ndx) => {
                                const isLast = autorSeries.length - 1 === ndx;
                                return(<>
                                    <Link to={"/seria/"+seria.replace(/ /g, "_")+'/1'} className="cyklklik aut-ser-list">{seria}</Link>{!isLast && ','}&nbsp;
                                </>);   
                            })}
                        </p>
                    </>}
                </div>
            </div>  
            <div id="ksiazkicont" className="row">
                {!ksiazki && <Loader />}
                {ksiazki && ksiazki.map((ksiazka) => (
                    <KsiazkaCard ksiazka={ksiazka} key={'a'+ksiazka.book_id} />
                ))}
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div id="pager" className="pagerbox">
                        <p>
                            {prev > 0 && <Link to={'/autor/'+autor+'/'+prev}><span className="prevpage">&lt; {prev}</span></Link>}
                            <span className="currpage">{strona}</span>
                            {next <= pagecount && <Link to={'/autor/'+autor+'/'+next}><span className="nextpage">{next} &gt;</span></Link>}
                        </p>
                    </div>
                </div>
            </div>


            
        </>
    )
}
export default Autor;