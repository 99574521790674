import { useEffect, useState } from "react";
import { PASSWORD } from "./Config";
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { API_KEY, API_URL } from "./Config";
import axios from "axios";

const Login = (props) => {
    const [password, setPassword] = useState();
    const [komunikat, setKomunikat] = useState(false);
    const [zalogowany, setZalogowany] = useState(props.loginState);
    const [helTitle, setHelTitle] = useState();
    const [helDesc, setHelDesc] = useState();
    const [helImg, setHelImg] = useState();
    let location = useLocation();

    function LoginHandler() {
        if (password === PASSWORD) {
            setKomunikat(false);
            setZalogowany(true);
            localStorage.setItem("logged", true);
            props.setLoginState(true);
            window.history.replaceState(null, "", location.pathname);
        } else {
            setKomunikat(true);
        };
    };

    function setBookData(autor, tytul) {
        let autorName = decodeURI(autor);
        let tytulName = decodeURI(tytul);
        axios.get(API_URL, {
            params: {
                type: 'book',
                selector: 'data',
                author: autorName,
                title: tytulName
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                console.log(response.data.data.item);
                setHelTitle(response.data.data.item.title + ' | ' + response.data.data.item.author[0]);
                setHelDesc(response.data.data.item.opis);
                setHelImg(response.data.data.item.foldername+'/'+response.data.data.item.filename+'.jpg');
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    function setAuthorData(autor) {
        let tempaut = autor.split('_');
        axios.get(API_URL, {
            params: {
                type: 'author',
                authorname: tempaut[0],
                authorsurname: tempaut.pop()
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                setHelTitle('Autor: ' + response.data.data.autor_name + ' ' + response.data.data.autor_surname);
                setHelDesc("Średnia ocena: "+response.data.data.rating+"/10 | Kategoria: "+response.data.data.tags);
                setHelImg('https://ksiazki.jakubratajczak.pl/writ.jpg');
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
        
    };

    function setSeriaData(seria) {
        let seriaName = decodeURI(seria.replaceAll('_', ' '));
        axios.get(API_URL, {
            params: {
                type: 'seria',
                serianame: seriaName
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                console.log(response);
                setHelTitle('Seria: '+response.data.data.seria_name);
                setHelDesc("Autor: "+response.data.data.seria_authors+" | Średnia ocena: " + response.data.data.seria_rating + " | Kategoria: "+response.data.data.seria_tags);
                setHelImg('https://ksiazki.jakubratajczak.pl/books.jpg');
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
        
    };

    function handleKeyDown(event) {
        if(event.code === 'Enter') {
            LoginHandler();
        };
    };

    function handleChange(event) {
        setPassword(event.target.value);
    };

    useEffect(() => {
        let ptc = location.pathname;
        if (ptc == '/login') {
            console.log('login page init');
            setHelTitle('Książki');
            setHelDesc('Katalog książek');
            setHelImg('https://ksiazki.jakubratajczak.pl/books.jpg');
        } else {
            console.log('other page init');
            console.log(ptc);
            ptc = ptc.split('/');
            console.log(ptc);
            if (ptc[1] == 'autorzy') {
                setHelTitle('Autorzy');
                setHelDesc('Katalog autorów');
                setHelImg('https://ksiazki.jakubratajczak.pl/writ.jpg');
            } else if (ptc[1] == 'serie') {
                setHelTitle('Serie');
                setHelDesc('Katalog serii');
                setHelImg('https://ksiazki.jakubratajczak.pl/books.jpg');
            } else if (ptc[1] == 'autor') {
                setAuthorData(ptc[2]);
            } else if (ptc[1] == 'seria') {
                setSeriaData(ptc[2]);
            } else {
                setBookData(ptc[1], ptc[2]);
            };
        };
        window.history.replaceState(null, "Login", "/login");
    }, [])

    return(
        <>
        <Helmet>
            <title>{helTitle}</title>
            <meta property="og:title" content={helTitle} />
            <meta property="og:description" content={helDesc} />
            <meta property="og:image" content={helImg} />
        </Helmet>
        <div className="row">
            <div className="login-login-container">
                <p className="introtext">Książki {zalogowany && <>ZALOGOWANY</>}</p>
                {komunikat && <p className="errorpass">Złe hasło</p>}
                <input className="login-login-pass" type="password" name="pass" placeholder="Hasło" onChange={handleChange} onKeyDown={handleKeyDown} />
                <br />
                <p className="login-login-login" onClick={() => LoginHandler()}>WEJDŹ</p>
            </div>
        </div>
        </>
    )
}

export default Login;