import { useEffect, useState } from "react";
import { API_KEY, API_URL } from "./Config";
import axios from "axios";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import KsiazkaCard from "./KsiazkaCard";
import Loader from "./Loader";
import {Helmet} from "react-helmet";

const Katalog = (props) => {
    const navigate = useNavigate();
    const [ksiazki, setKsiazki] = useState();
    const [pagecount, setPagecount] = useState();
    var { strona } = useParams();
    const location = useLocation();
    const [selectorName, setSelectorName] = useState(sessionStorage.getItem("selectorName"));
    const [sortType, setSortType] = useState(sessionStorage.getItem("sortType"));
    const [sortBy, setSortBy] = useState(sessionStorage.getItem("sortBy"));
    const [liczbaTotal, setLiczbaTotal] = useState(0);
    const filtryDoSelectora = [
        {value: 'ostatniododane', name: 'Ostanio dodane', },
        {value: 'tytulyaz', name: 'Tytuły A-Z', },
        {value: 'tytulyza', name: 'Tytuły Z-A', },
        {value: 'najnowsze', name: 'Najnowsze', },
        {value: 'najstarsze', name: 'Najstarsze', },
        {value: 'najlepsze', name: 'Najwyżej oceniane', },
        {value: 'najgorsze', name: 'Najniżej oceniane', }
    ];

    var prev = strona - 1;
    var next = strona;
    next++;

    function properPageChecker() {
        if (strona > pagecount) {
            window.location.replace('/katalog/'+pagecount);
        };
    };

    function sortingSetting(event) {
        navigate("/katalog/1");
        let selector = event.target.value;
        let seltab = {
            'ostatniododane' : {
                type: 'DESC',
                by: 'data_dodania'
            },
            'tytulyaz' : {
                type: 'ASC',
                by: 'title'
            },
            'tytulyza' : {
                type: 'DESC',
                by: 'title'
            },
            'najnowsze' : {
                type: 'DESC',
                by: 'data_wydania'
            },
            'najstarsze' : {
                type: 'ASC',
                by: 'data_wydania'
            },
            'najlepsze' : {
                type: 'DESC',
                by: 'rating'
            },
            'najgorsze' : {
                type: 'ASC',
                by: 'rating'
            }
        };
        sessionStorage.setItem("selectorName", selector)
        sessionStorage.setItem("sortType", seltab[selector].type);
        sessionStorage.setItem("sortBy", seltab[selector].by);
        setSelectorName(selector);
        setSortType(seltab[selector].type);
        setSortBy(seltab[selector].by);
    };

    function loadKsiazki(page) {
        axios.get(API_URL, {
            params: {
                type: 'books',
                limit: 8,
                curentpage: page,
                sortby: sortBy,
                sorttype: sortType
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                console.log(response);
                setLiczbaTotal(response.data.data.total_items);
                setKsiazki(response.data.data.items);
                setPagecount(response.data.data.total_pages);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    function szukajka() {
        navigate("/szukaj//1");
    }

    useEffect(() => {
        loadKsiazki(strona);
    }, []);

    useEffect(() => {
        properPageChecker();
    }, [pagecount]);

    useEffect(() => {
        loadKsiazki(strona);
    }, [strona]);

    useEffect(() => {
        window.scroll(0,0);
      }, [location]);

    useEffect(() => {
        sortBy != '' && loadKsiazki(strona);
    },[sortBy, sortType])

    return(
        <>
            <Helmet>
                <title>Katalog</title>
                <meta property="og:title" content="Książki" />
                <meta property="og:description" content="Katalog książek" />
                <meta property="og:image" content="https://ksiazki.jakubratajczak.pl/books.jpg" />
            </Helmet>
            <div className="row aut-info-row">
                <div className="col-lg-4 aut-name-surname">
                    <p className="aut-name">katalog</p>
                    <p className="aut-surname">książek</p>
                </div>
            </div>
            <div className="row licz">
                <div className="col-7 col-lg-4">
                    <p className="szukklas">
                        <i className="fas fa-times clearsearch" aria-hidden="true"></i>
                        <i className="fas fa-search" aria-hidden="true"></i>
                        <input type="text" name="sera" className="searchmebox" placeholder="Szukaj" onClick={szukajka}/>
                    </p>
                </div>
                <div className="col-5 col-lg-4">
                    <p className="liczks">Liczba pozycji: <span className="liczkslicz">{liczbaTotal}</span></p>
                </div>
                <div className="col-12 col-lg-4 sortmelikeboss">
                    <select id="sortselect" onChange={(e) => sortingSetting(e)} value={selectorName}>
                        {filtryDoSelectora && filtryDoSelectora.map((filtr) => {
                            return(
                                <option value={filtr.value}>{filtr.name}</option>
                            )
                        })}
                    </select>
                </div>  
            </div>
            <div id="ksiazkicont" className="row">
                {!ksiazki && <Loader />}
                {ksiazki && ksiazki.map((ksiazka) => (
                    <KsiazkaCard ksiazka={ksiazka} key={ksiazka.book_id} />
                ))}
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div id="pager" className="pagerbox">
                        <p>
                            {prev > 0 && <Link to={'/katalog/'+prev}><span className="prevpage">&lt; {prev}</span></Link>}
                            <span className="currpage">{strona}</span>
                            {next <= pagecount && <Link to={'/katalog/'+next}><span className="nextpage">{next} &gt;</span></Link>}
                        </p>
                    </div>
                </div>
            </div>


            
        </>
    )
}
export default Katalog;