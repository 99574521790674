import { Link } from "react-router-dom";

const KsiazkaCard = (props) => {
    const ksiazka = props.ksiazka;
    const searchParams = props.searchParams;
    var bookLink = '/'+ksiazka.author[0].replace(/ /g, "_")+'/'+ksiazka.title.replace(/ /g, "_");

    function searchHighlight(text, stext) {
        const searchRegExp = new RegExp(stext, "gi");
        // const searchRegExp = /{stext}/gi;
        const replaceWith = '<span class="sh">'+stext+'</span>';
        const newtext = text.replace(searchRegExp, replaceWith);
        return newtext;
    }

    function properSeriesNr(textNr) {
        textNr = textNr.substring(textNr.indexOf("_TOM_") + 5);
        textNr = textNr.replace(')', '');
        return textNr;
    }

    return(
        <div className="ksiazka-item col-lg-6" data-id={ksiazka.book_id} key={ksiazka.book_id}>
            <div className="ksiazka-item-onlybord">
                <div className="ksiazka-item-left-part">
                    <Link to={bookLink}>
                        <img title={ksiazka.book_id} className="grcovfix" src={ksiazka.foldername+'/'+ksiazka.filename+'.jpg'} />
                    </Link>
                </div>
                <div className="ksiazka-item-right-part">
                    <div className="upperpart">
                        <Link to={bookLink} className="ksiazka-title-link">
                            {searchParams &&<>
                                <p className="ksiazka-title" dangerouslySetInnerHTML={{__html: searchHighlight(ksiazka.title, searchParams)}} />
                            </>}
                            {!searchParams &&<>
                                <p className="ksiazka-title">{ksiazka.title}</p>
                            </>}
                        </Link>
                        <p className="ksiazka-autor">
                            {ksiazka.author && ksiazka.author.map((autor, ndw) => {
                                const isLast2 = ksiazka.author.length - 1 === ndw;
                                return <><Link key={autor} to={"/autor/"+autor.replace(/ /g, "_")+'/1'} className="ksiazka-autor-klik-link">{autor}</Link>{!isLast2 && ','} </>
                            })}
                        </p>
                        {ksiazka.series_name && <p className="ksiazka-cykl">Seria: <Link to={"/seria/"+ksiazka.series_name.replace(/ /g, "_")+'/1'} className="cyklklik-link">{ksiazka.series_name}</Link> (Tom {properSeriesNr(ksiazka.filename)})</p>}
                    </div>
                    <div className="bottompart">
                        <p className="ksiazka-tagi"><span className="flink"></span>
                            {ksiazka.tags && ksiazka.tags.map((tag, ndx) => {
                                const isLast = ksiazka.tags.length - 1 === ndx;
                                return <>{tag}{!isLast && ','} </>
                            })}
                        </p>
                        <p className="ksiazka-data">
                            <span className="ksiazka-data-rok">Wydano: <span className="grubasek">{ksiazka.data_wydania.substring(0, 4)}</span></span>
                            <span className="langdiv">|</span>Ocena: <span className="noc">{ksiazka.rating}</span><span className="noc2">/10</span></p>
                    </div>
                </div>
                <div className="ksiazka-item-download-part">
                    <a className="ksiazka-item-download-button gam" href={ksiazka.foldername+'/'+ksiazka.filename+'.mobi'} target="_blank"><i className="fas fa-download" aria-hidden="true"></i><span>mobi</span></a>
                    <a className="ksiazka-item-download-button gae" href={ksiazka.foldername+'/'+ksiazka.filename+'.epub'} target="_blank"><i className="fas fa-download" aria-hidden="true"></i><span>epub</span></a>
                    <a className="ksiazka-item-download-button gaz" href={ksiazka.foldername+'/'+ksiazka.filename+'.azw3'} target="_blank"><i className="fas fa-download" aria-hidden="true"></i><span>AZW3</span></a>
                    <Link to={bookLink} className="ksiazka-opis-cont-trig"><span className="blinkspan" data-id="0">Szczegóły<i className="fas fa-book" aria-hidden="true"></i></span></Link>
                </div>
            </div>
        </div>
    )
}
export default KsiazkaCard;