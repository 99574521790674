import './App.css';
import { BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import Katalog from './components/Katalog';
import Autorzy from './components/Autorzy';
import Autor from './components/Autor';
import Serie from './components/Serie';
import Seria from './components/Seria';
import Menu from './components/Menu';
import Ksiazka from './components/Ksiazka';
import Login from './components/Login';
import Szukaj from './components/Szukaj';
import { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import SzukajRed from './components/SzukajRed';
import AdmOceny from './components/AdmOceny';
import AdmOceny2 from './components/AdmOceny2';

function App() {
  const [loginState, setLoginState] = useState(localStorage.getItem("logged"));
  const [firstStart, setSFirstStart] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("selectorName", 'ostatniododane');
    sessionStorage.setItem("sortType", 'DESC');
    sessionStorage.setItem("sortBy", 'data_dodania');
    setSFirstStart(true);
  },[])

  return (
    <>
    <Helmet>
        <title>Katalog</title>
    </Helmet>
    <BrowserRouter>
      <div className='container' id="scrolltome">
      {loginState ? (
        <>
        <Menu loginState={loginState} />
        <Routes>
          {firstStart ? (
            <>
              <Route key="1" path='/' element={<Navigate to='/katalog/1' />}/>
              <Route key="2" path='/katalog' element={<Navigate to='1' />} />
              <Route key="3" path="/katalog/:strona" element={<Katalog />} />
              <Route key="4" path="/seria/:seria/:strona" element={<Seria />} />
              <Route key="5" path="/autorzy" element={<Autorzy />} />
              <Route key="6" path="/autor/:autor" element={<Navigate to='1' />} />
              <Route key="7" path="/autor/:autor/:strona" element={<Autor />} />
              <Route key="8" path="/serie" element={<Serie />} />
              <Route key="9" path="/seria/:seria" element={<Navigate to='1' />} />
              <Route key="10" path="/:autor/:tytul" element={<Ksiazka />} />
              <Route key="11" path="/szukaj/:zapytanie/:strona" element={<Szukaj />} />
              <Route key="12" path="/szukaj//:strona" element={<Szukaj />} />
              <Route key="13" path='/szukaj' element={<Navigate to='/szukaj//1' />} />
              <Route key="14" path="/szukaj/:zapytanie" element={<SzukajRed />} />
              <Route key="15" path="/adm/oceny" element={<AdmOceny />} />
              <Route key="15" path="/adm/oceny2" element={<AdmOceny2 />} />
            </>
          ) : (
            <></>
          )}

        </Routes>
        </>
      ) : (
        <Login setLoginState={setLoginState} loginState={loginState} />
      )}
      </div>
    </BrowserRouter>

    </>
  );
}

export default App;
