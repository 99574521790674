import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { API_KEY, API_URL } from "./Config";
import { useState, useEffect } from "react";
import KsiazkaCard from "./KsiazkaCard";
import Loader from "./Loader";
import { Helmet } from "react-helmet";

const Seria = () => {
    var { strona, seria } = useParams();
    const [ksiazki, setKsiazki] = useState();
    const [seriaName, setSeriaName] = useState();
    const [seriaId, setSeriaId] = useState();
    const [seriaAuthors, setSeriaAuthors] = useState();
    const [seriaBookCount, setSeriaBookCount] = useState();
    const [seriaRating, setSeriaRating] = useState();
    const [seriaTags, setSeriaTags] = useState();
    const [pagecount, setPagecount] = useState();
    const location = useLocation();

    var prev = strona - 1;
    var next = strona;
    next++;

    function setSeriaData(seria) {
        axios.get(API_URL, {
            params: {
                type: 'seria',
                serianame: seria
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                setSeriaId(response.data.data.seria_id);
                setSeriaAuthors(response.data.data.seria_authors);
                setSeriaRating(response.data.data.seria_rating);
                setSeriaTags(response.data.data.seria_tags);
                setSeriaName(response.data.data.seria_name);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
        
    };

    function properPageChecker() {
        if (strona > pagecount) {
            window.location.replace('/seria/'+seria+'/'+pagecount);
        };
    };

    function loadKsiazki(page) {
        axios.get(API_URL, {
            params: {
                type: 'books',
                limit: 8,
                seriesid: seriaId,
                curentpage: page,
                sortby: 'series_nr',
                sorttype: 'ASC'
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                setKsiazki(response.data.data.items);
                setPagecount(response.data.data.total_pages);
                setSeriaBookCount(response.data.data.total_items);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    useEffect(() => {
        setSeriaData(seria);
        
    }, []);

    useEffect(() => {
        properPageChecker();
    }, [pagecount]);

    useEffect(() => {
        seriaId && loadKsiazki(strona);
     }, [seriaId, strona]);

     useEffect(() => {
        window.scroll(0,0);
      }, [location]);

    return(
        <>
            <Helmet>
                <title>{seriaName}</title>
                <meta property="og:title" content={'Seria: '+seriaName} />
                <meta property="og:description" content={"Autor: "+seriaAuthors+" | Liczba tomów: "+seriaBookCount+" | Średnia ocena: " + seriaRating + " | Kategoria: "+seriaTags} />
                <meta property="og:image" content="https://ksiazki.jakubratajczak.pl/books.jpg" />
            </Helmet>
            <div className="row aut-info-row">
                <div className="col-lg-12 aut-name-surname">
                    <p className="aut-name">
                        {seriaAuthors && seriaAuthors.map((autor) => {
                            return(<>
                                <Link to={"/autor/"+autor.replace(/ /g, "_")+'/1'} className="cyklklik aut-ser-list aut-ser-list-detail">{autor}</Link>&nbsp;
                            </>); 
                        })}
                    </p>
                    <p className="aut-surname">{seriaName}</p>
                    <p className="aut-tagi">Średnia ocena serii: <span className="grratingvalue grubasek autratadd">{seriaRating}</span>/10</p>
                    <p className="aut-tagi"><span className="aut-book-counter">{seriaBookCount} <i className="fas fa-book" aria-hidden="true"></i></span><span className="langdiv">|</span>{seriaTags && seriaTags.map((tag, ndx) => {
                        const isLast = seriaTags.length - 1 === ndx;
                        return <>{tag}{!isLast && ','} </>
                    })}</p>
                </div>
            </div>
            <div id="ksiazkicont" className="row">
                {!ksiazki && <Loader />}
                {ksiazki && ksiazki.map((ksiazka) => (
                    <KsiazkaCard ksiazka={ksiazka} key={'a'+ksiazka.book_id} />
                ))}
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div id="pager" className="pagerbox">
                        <p>
                            {prev > 0 && <Link to={'/seria/'+seria+'/'+prev}><span className="prevpage">&lt; {prev}</span></Link>}
                            <span className="currpage">{strona}</span>
                            {next <= pagecount && <Link to={'/seria/'+seria+'/'+next}><span className="nextpage">{next} &gt;</span></Link>}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Seria;