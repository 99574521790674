import { API_KEY, API_URL } from "./Config";
import { useEffect, useState } from "react";
import axios from "axios";
import classNames from 'classnames';
import axiosThrottle from 'axios-request-throttle';
import ProgressBar from "@ramonak/react-progress-bar";
import moment from "moment/moment";
import 'moment/locale/pl';
import Loader from "./Loader";

const AdmOceny = () => {
    const [bookArray, setBookArray] = useState();
    const [bookArrayLenght, setBookArrayLenght] = useState(0);
    const [masterData, setMasterData] = useState([]);
    const [curNum, setCurNum] = useState(0);
    const [curLC, setCurLC] = useState(0);
    const [curGR, setCurGR] = useState(0);
    const [per, setPer] = useState(0);
    const [perLC, setPerLC] = useState(0);
    const [perGR, setPerGR] = useState(0);
    const [inProces, setInProces] = useState(false);
    const [startTime, setStartTime] = useState();
    const [elapsedTime, setElapsedTime] = useState();
    const [tabHead, setTabHead] = useState(false);
    const [remainigTime, setRemainigTime] = useState(0);
    const [remainigTimeLC, setRemainigTimeLC] = useState(0);
    const [remainigTimeGR, setRemainigTimeGR] = useState(0);

    let i = 0;
    axiosThrottle.use(axios, { requestsPerSecond: 1 });
    

    function initialBookData() {
        axios.get(API_URL, {
            params: {
                type: 'adm_books_rating_data'
            },
            headers: {
                Key: API_KEY
            }
        })
        .then(function (response) {
            setBookArray(response.data.data.data);
            setBookArrayLenght(Object.keys(response.data.data.data).length);
            masterUpdater(response.data.data.data);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });
    };

    function GetLC(book) {
        axios.get(API_URL, {
            params: {
                type: 'get_lc_rating',
                link: book.linklc
            },
            headers: {
                Key: API_KEY
            }
        })
        .then(function (response) {
            console.log('LC response: '+book.title+' '+book.book_id+'');
            console.log(response);
            setCurLC(curLC => curLC + 1);
            let lcnewrating = response.data.data.rating;
            GetGR(lcnewrating, book);
        })
        .catch(function (error) {
            console.log(error);
            if (error.message == "Network Error") {
                setCurLC(curLC => curLC + 1);
                let lcnewrating = 0;
                GetGR(lcnewrating, book);
            }
            console.log('LC ERROR: '+book.title+' '+book.book_id+'');
        })
        .finally(function () {
        });
    }

    function GetGR(lcnewrating, book) {
        axios.get(API_URL, {
            params: {
                type: 'get_gr_rating',
                isbn: book.isbn
            },
            headers: {
                Key: API_KEY
            }
        })
        .then(function (response) {
            console.log('GR response: '+book.title+' '+book.book_id+'');
            console.log(response);
            setCurGR(curGR => curGR + 1);
            let grnewraitng = response.data.data.rating;
            handleNewInfo(lcnewrating, grnewraitng, book);
        })
        .catch(function (error) {
            console.log(error);
            if (error.message == "Network Error") {
                setCurGR(curGR => curGR + 1);
                let grnewraitng = 0;
                handleNewInfo(lcnewrating, grnewraitng, book);
            };
            console.log('GR ERROR: '+book.title+' '+book.book_id+'');
        })
        .finally(function () {
        });
    }

    function masterUpdater(bookArray) {
        // initialBookData();
        setPer(0);
        setPerLC(0);
        setPerGR(0);
        setCurGR(0);
        setCurLC(0);
        setCurNum(0);
        setMasterData([]);
        setTabHead(true);
        setInProces(true);
        setStartTime(Date.now());
        bookArray.map((book) => {
            GetLC(book); 
        });
    }

    function handleNewInfo(lcnewrating, grnewraitng, book) {
        if (book.ocenagr != grnewraitng || book.ocenalc != lcnewrating) {
            axios.get(API_URL, {
                params: {
                    type: 'update_rating',
                    id: book.book_id,
                    rlc: lcnewrating,
                    rgr: grnewraitng
                },
                headers: {
                    Key: API_KEY
                }
            })
            .then(function (response) {
                setMasterData(masterData => [{ 
                    coverlink: book.coverlink,
                    book_id: book.book_id, 
                    title: book.title,
                    author: book.author,
                    cur_ocenagr: book.ocenagr,
                    cur_ocenalc: book.ocenalc,
                    cur_rating: book.rating,
                    new_rating: response.data.data.new_rating,
                    new_ocenagr: grnewraitng,
                    new_ocenalc: lcnewrating
                }, ...masterData]);
                setCurNum(curNum => curNum + 1);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
        } else {
            setCurNum(curNum => curNum + 1);
        }
    }

    useEffect(() => {
        // initialBookData();
    }, []);

    useEffect(() => {
        setPer(0);
        setPerLC(0);
        setPerGR(0);
    }, [bookArray]);

    useEffect(() => {
        setPerLC((curLC / bookArrayLenght)*100);
    }, [curLC]);

    useEffect(() => {
        const temptime = Date.now();
        const timetoend = ((temptime - startTime) / perLC) * (100 - perLC);
        perLC != 0 && setRemainigTimeLC(Math.round(timetoend));
        perLC == 0 && setRemainigTimeLC(0);
    }, [perLC]);

    useEffect(() => {
        setPerGR((curGR / bookArrayLenght)*100);
    }, [curGR]);

    useEffect(() => {
        const temptime = Date.now();
        const timetoend = ((temptime - startTime) / perGR) * (100 - perGR);
        perGR != 0 && setRemainigTimeGR(Math.round(timetoend));
        perGR == 0 && setRemainigTimeGR(0);
    }, [perGR]);

    useEffect(() => {
        setPer((curNum / bookArrayLenght)*100);
        {curNum == bookArrayLenght && setInProces(false)};
    }, [curNum]);

    useEffect(() => {
        const temptime = Date.now();
        const timetoend = ((temptime - startTime) / per) * (100 - per);
        per != 0 && setRemainigTime(Math.round(timetoend));
        per == 0 && setRemainigTime(0);
    }, [per]);

    return(
        <>
            <div className="row aut-info-row">
                <div className="col-lg-4 aut-name-surname">
                    <p className="aut-name">admin</p>
                    <p className="aut-surname">odświeżanie ocen</p>
                </div>
                <div className="col-lg-8">
                {tabHead && <>
                    <table className="table onlytext newadmtable">
                        <tbody>
                            <tr>
                                <td>LC</td>
                                <td>{curLC} z {bookArrayLenght}</td>
                                <td className="admnewonmob">{Math.round(perLC)+'%'}</td>
                                <td className="admnewnomob">
                                    <ProgressBar 
                                        completed={perLC}
                                        customLabel={Math.round(perLC)+'%'}
                                        bgColor="#a27b5c"
                                        labelAlignment="outside"
                                        baseBgColor="#dcd7c9"
                                        labelColor="#dcd7c9"
                                        animateOnRender
                                    />
                                </td>
                                <td><span className="admnewnomob">Pozostało:</span> {remainigTimeLC && inProces && remainigTimeLC != 0 && new Date(remainigTimeLC).toISOString().slice(11, 19)}</td>
                            </tr>
                            <tr>
                                <td>GR</td>
                                <td>{curGR} z {bookArrayLenght}</td>
                                <td className="admnewonmob">{Math.round(perGR)+'%'}</td>
                                <td className="admnewnomob">
                                    <ProgressBar 
                                        completed={perGR}
                                        customLabel={Math.round(perGR)+'%'}
                                        bgColor="#a27b5c"
                                        labelAlignment="outside"
                                        baseBgColor="#dcd7c9"
                                        labelColor="#dcd7c9"
                                        animateOnRender
                                    />
                                </td>
                                <td><span className="admnewnomob">Pozostało:</span> {remainigTimeGR && inProces && remainigTimeGR != 0 && new Date(remainigTimeGR).toISOString().slice(11, 19)}</td>
                            </tr>
                            <tr>
                                <td>UPDATE</td>
                                <td>{curNum} z {bookArrayLenght}</td>
                                <td className="admnewonmob">{Math.round(per)+'%'}</td>
                                <td className="admnewnomob">
                                    <ProgressBar 
                                        completed={per}
                                        customLabel={Math.round(per)+'%'}
                                        bgColor="#a27b5c"
                                        labelAlignment="outside"
                                        baseBgColor="#dcd7c9"
                                        labelColor="#dcd7c9"
                                        animateOnRender
                                    />
                                </td>
                                <td><span className="admnewnomob">Pozostało:</span> {remainigTime && inProces && remainigTime != 0 && new Date(remainigTime).toISOString().slice(11, 19)}</td>
                            </tr>
                        </tbody>
                    </table></>
                }
                </div>
            </div>
            <div className="row databox">
                <div className="col-lg-12">
                    {!inProces && <>
                        <p className="ksiazka-item-download-button adm-ocen-but" onClick={() => initialBookData()}>Rozpocznij</p>
                    </>}
                    <table className="table onlytext">
                    {tabHead && <>
                        <thead>
                            <tr>
                                <th scope="col">Tytuł</th>
                                <th scope="col">Autor</th>
                                <th scope="col">LC</th>
                                <th scope="col">GR</th>
                                <th scope="col">Rating</th>
                            </tr>
                        </thead>
                        
                    </>
                    }
                    <tbody>
                    {!inProces && bookArray && masterData.length == 0 && <>
                        <tr>
                            <td colSpan={5} className="center-me-adm">Wszystkie oceny aktualne</td>
                        </tr>
                    </>}
                    {masterData && masterData.map((item) => {
                        var classOcenaLC = classNames(
                            {
                                'adm_o_red': item.new_ocenalc < item.cur_ocenalc,
                                'adm_o_green': item.new_ocenalc > item.cur_ocenalc,
                            }
                        );
                        var classOcenaGR = classNames(
                            {
                                'adm_o_red': item.new_ocenagr < item.cur_ocenagr,
                                'adm_o_green': item.new_ocenagr > item.cur_ocenagr,
                            }
                        );
                        var classOcenaRating = classNames(
                            {
                                'adm_o_red': item.new_rating < item.cur_rating,
                                'adm_o_green': item.new_rating > item.cur_rating,
                            }
                        );
                        return(
                            <tr key={item.book_id}>
                                <td>{item.title}</td>
                                <td>{item.author}</td>
                                <td>{item.cur_ocenalc} => <span className={classOcenaLC}>{item.new_ocenalc}</span></td>
                                <td>{item.cur_ocenagr} => <span className={classOcenaGR}>{item.new_ocenagr}</span></td>
                                <td>{item.cur_rating} => <span className={classOcenaRating}>{item.new_rating}</span></td>
                            </tr>
                        )
                    })}
                    </tbody>
                    </table>
                    {inProces && <Loader />}
                </div>
            </div>
        </>
    )
}
export default AdmOceny;