import axios from "axios";
import { API_KEY, API_URL } from "./Config";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from './Loader';
import { Helmet } from "react-helmet";

const Serie = () => {
    const [serie, setSerie] = useState();
    const [literki, setLiterki] = useState();

    function loadSeries() {
        axios.get(API_URL, {
            params: {
                type: 'series',
                sortby: 'nazwa',
                sorttype: 'ASC'
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                console.log(response);
                setLiterki(response.data.data.letter_array);
                setSerie(response.data.data.items);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    };

    useEffect(() => {
        loadSeries();
    }, []);

    return(
        <>
        <Helmet>
            <meta property="og:title" content="Serie" />
            <meta property="og:description" content="Katalog serii" />
            <meta property="og:image" content="https://ksiazki.jakubratajczak.pl/books.jpg" />
        </Helmet>
        <a href="#scrolltome" className="scrolltotopbox"><i className="fa fa-arrow-alt-circle-up" aria-hidden="true"></i></a>
            <div className="row aut-info-row">
                <div className="col-lg-4 aut-name-surname">
                    <p className="aut-name">lista</p>
                    <p className="aut-surname">serii</p>
                </div>
                <div className="col-lg-8">
                    <div className="literkowalista">
                        {!literki && <Loader />}
                        {literki && literki.map((literka) => (
                            <a href={'#'+literka}>{literka.toLowerCase()}</a>
                        ))}
                    </div>
                </div>
            </div>
            {!literki && <Loader />}
            {literki && literki.map((literka) => (
                <><div id={literka} className="row autorzy-list-row">
                    <div className="col-lg-12 autor-list-item ksiazka-item autorzy-main-letter">
                        <p>{literka}</p>
                    </div>
                
                {serie && serie.map((seria) => {
                   if (seria.seria_sort_letter === literka) {
                    return <>
                    <div className="col-lg-3 autor-list-item ksiazka-item">
                        <Link className="autorzylink ksiazka-item-onlybord-aut" to={"../seria/"+seria.name.replace(/\s/g, '_')+'/1'}>
                                <div className="aut-pl"> 
                                    <p className="aut-name-list">
                                        {seria.serie_authors && seria.serie_authors.map((author, ndx) => {
                                            const isLast = seria.serie_authors.length - 1 === ndx;
                                            return <>{author}{!isLast && ','} </>
                                        })}
                                    </p>
                                    <p className="aut-surname-list">{seria.name}</p>
                                </div>
                                <div className="aut-pr"> 
                                    <span className="aut-book-counter">{seria.book_count} <i className="fas fa-book" aria-hidden="true"></i></span>
                                </div>
                        </Link>
                    </div>
                    </>
                   };
                })}
                </div>
                </>
            ))}
        </>
    )
}
export default Serie;