import { NavLink } from "react-router-dom"
import { ReactSession } from 'react-client-session';

const Menu = (props) => {
    return(
        <>
            {props.loginState &&
                <div className="row">
                    <div className="col-lg-12 header-box masternavbar">
                        <NavLink to="/katalog" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "activeasbitch" : ""}>Katalog</NavLink>
                        <NavLink to="/autorzy" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "activeasbitch" : ""}>Autorzy</NavLink>
                        <NavLink to="/serie" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "activeasbitch" : ""}>Serie</NavLink>
                    </div>
                </div>
            }
        </>
    )
}
export default Menu