import axios from "axios";
import { API_KEY, API_URL } from "./Config";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import {Helmet} from "react-helmet";

const Autorzy = () => {
    const [autorzy, setAutorzy] = useState();
    const [literki, setLiterki] = useState();

    function loadAutors() {
        axios.get(API_URL, {
            params: {
                type: 'authors',
                sortby: 'nazwisko'
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                console.log(response);
                setLiterki(response.data.data.letter_array);
                setAutorzy(response.data.data.items);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    };

    useEffect(() => {
        loadAutors();
    }, []);

    return(
        <>
        <Helmet>
            <title>Autorzy</title>
            <meta property="og:title" content="Autorzy" />
            <meta property="og:description" content="Katalog autorów" />
            <meta property="og:image" content="https://ksiazki.jakubratajczak.pl/writ.jpg" />
        </Helmet>
        <a href="#scrolltome" className="scrolltotopbox"><i className="fa fa-arrow-alt-circle-up" aria-hidden="true"></i></a>
            <div className="row aut-info-row">
                <div className="col-lg-4 aut-name-surname">
                    <p className="aut-name">lista</p>
                    <p className="aut-surname">autorów</p>
                </div>
                <div className="col-lg-8">
                    <div className="literkowalista">
                        {!literki && <Loader />}
                        {literki && literki.map((literka) => (
                            <a href={'#'+literka}>{literka.toLowerCase()}</a>
                        ))}
                    </div>
                </div>
            </div>
            {!literki && <Loader />}
            {literki && literki.map((literka) => (
                <><div id={literka} className="row autorzy-list-row">
                    <div className="col-lg-12 autor-list-item ksiazka-item autorzy-main-letter">
                        <p>{literka}</p>
                    </div>
                
                {autorzy && autorzy.map((autor) => {
                   if (autor.author_sort_letter == literka) {
                    return <>
                    <div className="col-lg-3 autor-list-item ksiazka-item">
                        <Link className="autorzylink ksiazka-item-onlybord-aut" to={"../autor/"+autor.name.replace(/\s/g, '_')+"_"+autor.surname+'/1'}>
                                <div className="aut-pl"> 
                                    <p className="aut-name-list">{autor.name}</p>
                                    <p className="aut-surname-list">{autor.surname}</p>
                                </div>
                                <div className="aut-pr"> 
                                    <span className="aut-book-counter">{autor.book_count} <i className="fas fa-book" aria-hidden="true"></i></span>
                                </div>
                        </Link>
                    </div>
                    </>
                   };
                })}
                </div>
                </>
            ))}
        </>
    )
}
export default Autorzy;