import { Helmet } from "react-helmet";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import KsiazkaCard from "./KsiazkaCard";
import Loader from "./Loader";
import { API_KEY, API_URL } from "./Config";

const Szukaj = () => {
    var { zapytanie, strona } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSerchParams] = useState('');
    const [pagecount, setPagecount] = useState();
    const [liczbaTotal, setLiczbaTotal] = useState(0);
    const [ksiazki, setKsiazki] = useState();
    var prev = strona - 1;
    var next = strona;
    next++;

    function szukajka(event) {
        setSerchParams(event.target.value);
        navigate("/szukaj/"+event.target.value+"/1");
    }

    function loadKsiazki(page, spar) {
        axios.get(API_URL, {
            params: {
                type: 'search',
                limit: 8,
                curentpage: page,
                searchval: spar
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                console.log(response);
                setLiczbaTotal(response.data.data.total_items);
                setKsiazki(response.data.data.items);
                setPagecount(response.data.data.total_pages);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    };

    useEffect(() => {
        setSerchParams(zapytanie);
    }, [zapytanie]);

    useEffect(() => {
        window.scroll(0,0);
      }, [location]);;

    useEffect(() => {
        loadKsiazki(strona, zapytanie);
    }, [strona, zapytanie]);

    return(
        <>
            <Helmet>
                <title>Szukaj</title>
                <meta property="og:title" content="Książki" />
                <meta property="og:description" content="Wyszukiwarka książek" />
                <meta property="og:image" content="https://ksiazki.jakubratajczak.pl/books.jpg" />
            </Helmet>
            <div className="row aut-info-row">
                <div className="col-lg-4 aut-name-surname">
                    <p className="aut-name">wyszukiwarka</p>
                    <p className="aut-surname">książek</p>
                </div>
                <div className="col-lg-8">
                    <p className="szukany-tekst">Treści z frazą: <b>"{searchParams}"</b></p>
                </div>
            </div>

            <div className="row licz">
                <div className="col-7 col-lg-4">
                    <p className="szukklas">
                        <i className="fas fa-times clearsearch" aria-hidden="true"></i>
                        <i className="fas fa-search" aria-hidden="true"></i>
                        <input autoFocus id="Search" type="text" name="sera" type="search" autocomplete="off" className="searchmebox" placeholder="Szukaj" onChange={szukajka} value={searchParams}/>
                    </p>
                </div>
                <div className="col-5 col-lg-4">
                    {zapytanie && <><p className="liczks">Liczba pozycji: <span className="liczkslicz">{liczbaTotal}</span></p></>}
                </div>
                <div className="col-12 col-lg-4 sortmelikeboss">

                </div>  
            </div>
            {!zapytanie && <>
                <div id="ksiazkicont" className="row">
                    <p className="search-no-found">Wyszukiwane hasło jest za krótkie.</p>
                </div>
            </>}
            {(zapytanie && liczbaTotal == 0 ) && <>
                <div id="ksiazkicont" className="row">
                    <p className="search-no-found">Nic nie znaleziono dla <b>"{searchParams}"</b></p>
                </div> 
            </>}
            {zapytanie && <>
            <div id="ksiazkicont" className="row">
                {!ksiazki && <Loader />}
                {ksiazki && ksiazki.map((ksiazka) => (
                    <KsiazkaCard ksiazka={ksiazka} key={ksiazka.book_id} searchParams={searchParams} />
                ))}
            </div>
            </>}
            {(zapytanie && liczbaTotal !== 0 ) && <>
                <div className="row">
                    <div className="col-lg-12">
                        <div id="pager" className="pagerbox">
                            <p>
                                {prev > 0 && <Link to={'/szukaj/'+zapytanie+'/'+prev}><span className="prevpage">&lt; {prev}</span></Link>}
                                <span className="currpage">{strona}</span>
                                {next <= pagecount && <Link to={'/szukaj/'+zapytanie+'/'+next}><span className="nextpage">{next} &gt;</span></Link>}
                            </p>
                        </div>
                    </div>
                </div>
            </>}

        </>
    )
}
export default Szukaj;