import { Link, useParams, useLocation } from "react-router-dom";
import { API_KEY, API_URL } from "./Config";
import axios from "axios";
import { useEffect, useState } from "react";
import img_grlog from '../img/dp_gr_logo.jpg';
import img_lclogo from '../img/dp_lc_logo.jpg';
import img_pol from '../img/pol.svg';
import img_eng from '../img/eng.svg';
import moment from "moment/moment";
import 'moment/locale/pl';
import { Helmet } from "react-helmet";

const Ksiazka = () => {
    let location = useLocation();
    var { autor, tytul } = useParams();
    const [ksiazka, setKsiazka] = useState();
    const [ksiazkiWSerii, setKsiazkiWSerii] = useState();
    const [iloKWS, setIloKWS] = useState();
    const [seriaId, setSeriaId] = useState(null);
    let cururl = window.location.href;

    function loadKsiazka(autor, tytul) {
        axios.get(API_URL, {
            params: {
                type: 'book',
                selector: 'data',
                author: autor,
                title: tytul
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                console.log(response.data.data.item);
                setSeriaId(response.data.data.item['series_id']);
                setKsiazka(response.data.data.item);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "instant"
                  });
            });
    };

    function properSeriesNr(textNr) {
        textNr = textNr.substring(textNr.indexOf("_TOM_") + 5);
        textNr = textNr.replace(')', '');
        return textNr;
    }
    
    function flagSelector(lang) {
        if (lang == 'pol') {
            return <img className="dplangflag" src={img_pol} />
        } else {
            return <img className="dplangflag" src={img_eng} />
        };
    };

    function loadKsiazki() {
        axios.get(API_URL, {
            params: {
                type: 'books',
                limit: 0,
                seriesid: seriaId,
                curentpage: 1,
                sortby: 'series_nr',
                sorttype: 'ASC'
            },
            headers: {
                Key: API_KEY
            }
        })
            .then(function (response) {
                console.log(response);
                setIloKWS(response.data.data.total_items)
                setKsiazkiWSerii(response.data.data.items);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    useEffect(() => {
        seriaId && loadKsiazki();
    }, [seriaId]);


    useEffect(() => {
        loadKsiazka(autor, tytul);
    }, []);

    useEffect(() => {
        loadKsiazka(autor, tytul);
    }, [autor, tytul]);

    moment().locale('pl');
    return(
        <>

            {ksiazka && <>
                <Helmet>
                    <title>{ksiazka.title + ' | ' + ksiazka.author[0]}</title>
                    <meta property="og:url" content={location} />
                    <meta property="og:title" content={ksiazka.title + ' | ' + ksiazka.author[0]} />
                    <meta property="og:description" content={ksiazka.opis} />
                    <meta property="og:image" content={ksiazka.foldername+'/'+ksiazka.filename+'.jpg'} />
                </Helmet>
                <div className="row" key={ksiazka.book_id}>
                    <div className="col-lg-3 dp-cover-container">
                        <img className="dp-cover grcovfix" src={ksiazka.foldername+'/'+ksiazka.filename+'.jpg'} alt="" />
                    </div>
                    <div className="col-lg-6 d-flex align-items-end">
                        <div className="dp-container-bot">
                            <br />
                            <p className="aut-name">
                                {ksiazka.author && ksiazka.author.map((autorinner, ndx) => {
                                    const isLast = ksiazka.author.length - 1 === ndx;
                                    return <><Link key={autorinner} className="hidelink" to={"/autor/"+autorinner.replace(/ /g, "_")+'/1'}><span>{autorinner}</span></Link>{!isLast && ','} </>
                                })}
                            </p>
                            <p className="aut-surname">{ksiazka.title}</p>
                            <br/>
                            {ksiazka.series_name && <p className="dp-series">Seria: <span className="grubasek"><Link className="hidelink" to={"/seria/"+ksiazka.series_name.replace(/ /g, "_")+'/1'}>{ksiazka.series_name}</Link> (Tom {properSeriesNr(ksiazka.filename)})</span></p>}
                            <p className="dp-tag">Kategoria: <span className="grubasek"><span>{ksiazka.tags && ksiazka.tags.map((tag, ndx)=>{
                                const isLast = ksiazka.tags.length - 1 === ndx;
                                return <><span key={tag}>{tag}{!isLast && ','}</span> </>
                            })}<span></span></span></span></p>
                            <p className="dp-data">Data wydania: <span className="grubasek">{moment(ksiazka.data_wydania).format('D MMMM Y')}</span><span className="dp-org-div">|</span>Język: {flagSelector(ksiazka.lang)}</p>
                            <p className="dp-tag">ISBN: <span className="grubasek">{ksiazka.isbn}</span></p>
                            <p className="dp-rating-line">
                                <span className="dp-rating-total">Ocena: 
                                    <span className="dp-rating-total-val">
                                        <span className="dp-rating-total-1">&nbsp;{ksiazka.rating}</span>
                                        <span className="dp-rating-total-2">/10</span>
                                    </span>
                                </span> (&nbsp;
                                    <a className="dp-rating-gr" href={"https://www.goodreads.com/book/isbn/"+ksiazka.isbn} target="_blank">
                                        <img className="dp-rating-logo" src={img_grlog} />
                                        <span className="ajuzci">&nbsp;{ksiazka.ocenagr}/5&nbsp;</span>
                                    </a>
                                    <a className="dp-rating-lc" href={ksiazka.linklc} target="_blank">
                                        <img className="dp-rating-logo" src={img_lclogo} />
                                        <span className="ajuzci">&nbsp;{ksiazka.ocenalc}/10&nbsp;</span>
                                    </a>)</p>
                            <div className="dp-container-bot-fake-line"></div> 
                        </div>
                    </div>
                    <div className="col-lg-3 d-flex align-items-end">
                        <div className="dp-down-cont">
                            <a className="dp-download-link dp-download-mobi gam ksiazka-item-download-button" href={ksiazka.foldername+'/'+ksiazka.filename+'.mobi'}><i className="fas fa-download" aria-hidden="true"></i><span>MOBI</span></a>
                            <a className="dp-download-link dp-download-azw3 gae ksiazka-item-download-button" href={ksiazka.foldername+'/'+ksiazka.filename+'.azw3'}><i className="fas fa-download" aria-hidden="true"></i><span>AZW3</span></a>
                            <a className="dp-download-link dp-download-epub gaz ksiazka-item-download-button" href={ksiazka.foldername+'/'+ksiazka.filename+'.epub'}><i className="fas fa-download" aria-hidden="true"></i><span>EPUB</span></a>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <p className="dp-desc">{ksiazka.opis}</p>
                    </div>
                </div>
                {iloKWS > 1 && 
                    <div className="col-lg-12">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 dp-series-list-title">
                                <p>Inne książki z serii <span>{ksiazka.series_name}</span></p>
                            </div>
                            {ksiazkiWSerii && ksiazkiWSerii.map((ksiazkaitem) => {
                                if (ksiazkaitem['book_id'] !== ksiazka.book_id) {
                                    return(
                                        <div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
                                            <Link className="dp-series-link" to={'/'+ksiazkaitem['author'][0].replace(/ /g, "_")+'/'+ksiazkaitem['title'].replace(/ /g, "_")}>
                                                <div className="dp-series-item-cover">
                                                    <img className="grcovfix" src={ksiazkaitem['foldername']+'/'+ksiazkaitem['filename']+'.jpg'} />
                                                </div>
                                                <div className="dp-series-item-name">
                                                    Tom {properSeriesNr(ksiazkaitem['filename'])}<br />
                                                    <span>{ksiazkaitem['title']}</span>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                }
            </>}
        </>
    )
}
export default Ksiazka;